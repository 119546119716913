<template>
  <!-- create dialog using vuetify for data entry -->
  <v-dialog width="98%" :value="value" @input="$emit('input', $event)">
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation 
    >
      <v-card class="pt-8">
        <v-card-text style="height: 80vh">
          <VRow>
            <v-col md="3">
              <v-text-field :disabled="readMode" :rules="requiredRule()" :label="$t('hr.time.to-date')" type="date"
                            outlined v-model="form.end_date">
              </v-text-field>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" @change="form.contract = null,form.shift = null" :items="employees"
                              item-text="name" item-value="id" :label="$t('hr.time.emp-name')" outlined
                              v-model="form.employee">
              </v-autocomplete>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" @change="form.employee = null,form.shift = null" :items="contracts"
                              item-text="name" item-value="id" :label="$t('hr.calculation.contract')" outlined
                              v-model="form.contract">
              </v-autocomplete>
            </v-col>
            <v-col md="3">
              <v-autocomplete :disabled="readMode" @change="form.contract = null,form.employee = null" :items="shifts"
                              item-text="name" item-value="id" :label="$t('hr.calculation.shift')" outlined
                              v-model="form.shift">
              </v-autocomplete>
            </v-col>
          </VRow>
          <v-row>
            <v-col md="8">
              <v-text-field :disabled="readMode" :label="$t('hr.vacation.note')" outlined v-model="form.note">
              </v-text-field>
            </v-col>
            <v-col md="4">
              <v-btn v-if="!readMode" color="blue" style="width: 100%; color: white" @click="submit" type="button">{{
                  $t('hr.calculation.calculate')
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-8"></v-divider>
          <v-row no-gutters v-if="!readMode">
            <v-btn @click="save" :disabled="!isThereCalculation" class="mx-1" style="width: 20%; color: white"
                   color="blue">
              {{ $t('save') }}
            </v-btn>
            <v-btn :disabled="!isThereCalculation" outlined class="mx-1" @click="$emit('input', false)">{{
                $t('cancel')
              }}
            </v-btn>
            <v-btn :disabled="!isThereCalculation" outlined class="mx-1">{{ $t('hr.time.export') }}</v-btn>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                class="mb-3"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-data-table :dark="$store.state.isDarkMode"
              :search="search"
              :headers="headers"
              id="table"
              class="flex-grow-1"
              :items="data"
              :loading="$store.state.calculations.loading"
              style="border: 1px solid #999"
              hide-default-footer
            >
            </v-data-table>

          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {requiredRule} from "@/helpers/validation-rules";

export default {
  name: 'CalculationModal',
  data() {
    return {
      valid: false,
      search: '',
      form: {
        end_date: '',
        employee: null,
        contract: null,
        shift: null,
        note: '',
      }
    }
  },
  methods: {
    requiredRule() {
      return requiredRule
    },
    ...mapActions({
      fetchEmployees: 'employees/fetchEmployees',
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
      generateCalculation: 'calculations/generate',
    }),
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        this.generateCalculation(this.form)
      }
    },
    async save() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        await this.generateCalculation({...this.form, status: 'save'})
        this.$emit('input', false)
      }
    }
  },
  mounted() {
    this.fetchEmployees()
    this.fetchContracts()
    this.fetchShifts()
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      calculation: 'calculations/isThereCalculation',
      data: 'calculations/calculationData',
      headers: 'calculations/calculationHeaders',
      isThereCalculation: 'calculations/isThereCalculation',
      employees: 'employees/employees',
      contracts: 'employees/contracts',
      shifts: 'employees/shifts'
    })
  },
  watch: {
    calculation(nV) {
      if (nV) {
        this.form.employee = nV.employee_id ?? null
        this.form.contract = nV.contract_id ?? null
        this.form.shift = nV.shift_id ?? null
        this.form.end_date = nV.date ?? null
      }
    }
  }
}
</script>
<style scoped>
#table >>> th, #table >>> td {
  text-align: center !important;
}
</style>
