<template>
  <v-container class="px-10">
    <v-row justify="center">
      <v-col md="12">
        <v-btn color="success" class="my-9 text-capitalize" @click="() => {
          display = true; item = null; readMode = false; this.setCalculation(null)
        }"
        >{{ $t('hr.calculation.add') }}
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col class="mt-8">
        <h3 class="mb-2">{{ $router.currentRoute.name }}</h3>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          class="mb-3"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table :dark="$store.state.isDarkMode"
          :search="search"
          :headers="headers"
          id="table"
          :items="data"
          :loading="$store.state.calculations.loading"
          style="border: 1px solid #999"
          :options.sync="$store.state.calculations.options"
          :server-items-length="$store.state.calculations.serverTotal"
          disable-sort
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex justify-center">
              <div class="pl-8">
                <v-btn color="blue" @click="sendMail(item.id)" class="white--text">
                  {{ $t('hr.calculation.send-mail') }}
                </v-btn>
              </div>
              <v-icon
                color="red"
                style="cursor: pointer"
                class="px-2"
                @click="handleDelete(item)"
              >mdi-delete
              </v-icon>
              <v-icon
                color="blue"
                class="mx-2"
                style="cursor: pointer"
                @click="handleDisplay(item)"
              >mdi-eye
              </v-icon>
            </div>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <CalculationModal v-model="display" :item="item" :read-mode="readMode"></CalculationModal>
  </v-container>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import CalculationModal from "@/views/dashboard/modules/HR/calculations/CalculationModal.vue";

export default {
  components: {CalculationModal},
  data() {
    return {
      readMode: false,
      search: '',
      display: false,
      item: null,
    };
  },
  mounted() {
    this.fetch()
  },
  watch: {
    "$store.state.calculations.options": function () {
      this.fetch()
    },
  },
  computed: {
    ...mapGetters({
      data: 'calculations/data',
      headers: 'calculations/headers',
    })
  },
  methods: {
    ...mapActions({
      fetch: 'calculations/fetch',
      delete: 'calculations/delete',
      setCalculation: 'calculations/setCalculation',
      sendMail: 'calculations/sendMail',
    }),
    handleDisplay(item) {
      this.item = item
      this.setCalculation(this.item)
      this.readMode = true
      this.display = true
    },
    async handleDelete(item) {
      await this.delete(item.id)
    },
    handleEdit(item) {
      this.item = item
      this.readMode = false
      this.display = true
    },
  },
};
</script>
<style scoped>
#table >>> th, #table >>> td {
  text-align: center !important;
}
</style>
